import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { Timestamp, collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import NotificationRow from '../NotificationRow';
import { addNotifData, decrementUnreadCount, incrementUnreadCount, removeNotifData } from '@/lib/features/notifSlice';
import { reportBugsnagError } from '@/utils/api';
import { NotifiableError } from '@bugsnag/js';
import { NotificationData } from '..';


const FloatingNotifications = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { id, userVerified } = useAppSelector(
    (state: RootState) => state.user.userData
  );
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [pendingNotifications, setPendingNotifications] = useState<NotificationData[]>([]);

  const addNotification = (notification: NotificationData) => {
    dispatch(incrementUnreadCount());
    dispatch(addNotifData([notification]));
    setNotifications((prev) => {
      if (prev.length < 2)
        return [notification, ...prev]
      else {
        setPendingNotifications((prev) => [...prev, notification]);
        return [...prev];
      }
    });
    setTimeout(() => {
      setNotifications((prev) => {
        const index = prev.findIndex((notification) => notification.id === notification.id);
        if (index !== -1) {
          prev.splice(index, 1);
        }
        return [...prev];
      });
    }, 5 * 1000);
  };

  useEffect(() => {
    if (notifications.length < 2 && pendingNotifications.length > 0) {
      addNotification(pendingNotifications[0]);
      setPendingNotifications((prev) => prev.slice(1));
    }
  }, [notifications]);

  useEffect(() => {
    if (id && userVerified) {
      try {
        const db = getFirestore();

        const userId = getAuth().currentUser?.uid;
        if (userId) {
          const notificationsRef = collection(db, 'CharNotifications', userId, 'notifications');
          const currTimestamp = Timestamp.now();

          const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                const newNotification = change.doc.data() as NotificationData;
                const createdAtTimestamp: Timestamp = change.doc.data().createdAt;
                newNotification.createdAt._seconds = createdAtTimestamp.seconds;
                newNotification.createdAt._nanoseconds = createdAtTimestamp.nanoseconds;

                if (!newNotification.read && (newNotification.createdAt._seconds > currTimestamp.seconds)) {
                  addNotification(newNotification);
                }
              } else if (change.type === 'modified') {
                const modifiedNotification = change.doc.data() as NotificationData;
                setNotifications((prev) => {
                  const index = prev.findIndex((notification) => notification.id === modifiedNotification.id);
                  if (index !== -1) {
                    if (modifiedNotification.read) {
                      prev.splice(index, 1);
                    } else {
                      prev[index] = modifiedNotification;
                    }
                  }
                  return [...prev];
                });
              } else if (change.type === 'removed') {
                const removedNotification = change.doc.data() as NotificationData;
                setNotifications((prev) => {
                  const index = prev.findIndex((notification) => notification.id === removedNotification.id);
                  if (index !== -1)
                    prev.splice(index, 1);
                  return [...prev];
                });
                dispatch(removeNotifData(removedNotification.id));
                if (!removedNotification.read) {
                  dispatch(decrementUnreadCount());
                }
              }
            });
          });
        }
      } catch (error) {
        reportBugsnagError(
          new Error(
            `Error listening to notifications: ${error}`
          ) as NotifiableError,
          id
        );
      }
    }
  }, [userVerified, id]);

  return (
    <div className={styles.floatingNotificationSection}>
      {notifications?.map((notification, index) => {
        return (
          <NotificationRow
            data={notification}
            key={index}
            className={index === 0 ? 'slideRight' : 'slideDown'}
            floating
          />
        );
      })}
    </div>
  );
};

export default FloatingNotifications;
