'use client';

import { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import useApi from '@/hooks/useApi';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import emptyResults from '/public/static/assets/images/emptyResultsLogo.webp';
import avatarIcon from '/public/static/assets/images/avatar.png';
import Loader from '@/components/Loader';
import { fetchChatdata, reportEvent } from '@/utils/api';
import {
  setChatData,
  setChatHistory,
  setChatsGenerated,
  updateChatCarouselData,
  updateChatCarouselDataatStart,
} from '@/lib/features/chatSlice';
import ErrorScreen from '@/components/ErrorScreen/Index';
import { setError, setErrorToast } from '@/lib/features/deviceSlice';
import { isDesktop } from '@/utils/device';
import sanitizeHtml from 'sanitize-html';
import { smallIconUrl } from '@/utils';

type CharChatHistoryData = {
  chatSessionId: string;
  characterId: string;
  user_id: string;
  id: string;
  characterName: string;
  description: string;
  characterImageUrl: string;
  createdAt: {
    _seconds: string;
  };
  updatedAt: {
    _seconds: number;
  };
  lastMessage: string;
};

type IChatHistoryResponse = {
  status: string;
  body: CharChatHistoryData[];
  pageInfo: {
    cursor: string | null;
    hasNextPage: boolean;
  }
};

const Chat: React.FC<{
  chatId?: string | null;
}> = ({ chatId }) => {
  const {
    id: userId,
    email,
    age,
    gender,
  } = useAppSelector((state: RootState) => state.user.userData);
  const [isHistoryEmpty, setEmptyHistory] = useState<boolean>(false);
  const { chatHistory, chatCarouselData } = useAppSelector(
    (state: RootState) => state.chat
  );
  const [isLoader, showLoader] = useState(false);
  const { orientation } = useAppSelector(
    (state: RootState) => state.device.deviceData
  );
  const fetchInProgress = useRef<boolean>(false);
  const [isNextPageAvailable, setNextPage] = useState(false);
  const [pageCursor, setPageCursor] = useState<string | null>(null);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [chatDataFetched, setDataStatus] = useState(false);
  const { responseData, isLoading, fetchData, error } =
    useApi<IChatHistoryResponse>(
      'GET',
      `/ai/api/char/v1/chatHistory/${userId}?pageSize=15`
    );
  const handleButonClick = () => {
    router.push('/dashboard/characters?category=recommended');
  };
  const trackScrolling = async () => {
    const scrollElm = document.getElementById(isDesktop() ? 'recentChats' : 'scrollableContainer');
    if (scrollElm) {
      const { scrollTop, clientHeight, scrollHeight } = scrollElm;
      if (
        scrollTop + clientHeight + 50 >= scrollHeight &&
        isNextPageAvailable &&
        !fetchInProgress.current
      ) {
        showLoader(true);
        fetchInProgress.current = true;
        await fetchData(
          `/ai/api/char/v1/chatHistory/${userId}?pageSize=15&cursor=${pageCursor}`
        );
        setTimeout(() => {
          fetchInProgress.current = false;
        }, 1000);
      }
    }
  };
  const getTime = (time: number | undefined) => {
    if (time) {
      const createdAtMinutes = (Date.now() - time * 1000) / 1000 / 60;
      let createdAtHours: number;
      let noOfDays: number;
      if (createdAtMinutes > 60) {
        createdAtHours = createdAtMinutes / 60;
        if (createdAtHours > 24) {
          noOfDays = Math.floor(createdAtHours / 24);
          return `${noOfDays} d`;
        }
        return `${Math.floor(createdAtHours)} hr`;
      }
      return `${Math.floor(createdAtMinutes)} min`;
    }
    return '0 min';
  };
  const handleRedirect = (chatSessionId: string, characterId: string) => {
    if (chatId) {
      router.replace(
        `/dashboard/chats?chatId=${chatSessionId}&charId=${characterId}`
      );
      setTimeout(() => {
        showLoader(false);
      }, 200);
    } else {
      router.push(
        `/dashboard/chats?chatId=${chatSessionId}&charId=${characterId}`
      );
      setTimeout(() => {
        showLoader(false);
      }, 200);
    }
  };
  const startChat = (charId: string) => {
    document.getElementById('scrollableContainer')?.scrollTo(0, 0);
    const startCharacterChat = async (addAtStart: boolean = false) => {
      if (!isDesktop()) {
        showLoader(true);
      }
      const chatData = await fetchChatdata(userId as string, charId);
      if (!chatData) {
        dispatch(setErrorToast({ showErrorToast: true }));
        showLoader(false);
        return;
      }
      const { status } = chatData;
      if (status === 'Success') {
        const {
          body: {
            chatHistoryList,
            chatSessionId,
            characterId,
            characterImageUrl,
            characterName,
            description,
            voiceId,
            skillSet,
            tip,
            createdBy,
            disabledBy,
            createdByUserId
          },
        } = chatData;
        dispatch(
          setChatData({
            chatSessionId,
            chatHistoryList,
            characterId,
            characterImageUrl,
            characterName,
            description,
            voiceId,
            skillSet,
            tip,
            createdBy,
            disabledBy,
            createdByUserId
          })
        );
        if (addAtStart) {
          dispatch(
            updateChatCarouselDataatStart({
              chatSessionId,
              characterId,
              user_id: userId as string,
              characterName,
              characterImageUrl,
            })
          );
        } else {
          dispatch(
            updateChatCarouselData({
              chatSessionId,
              characterId,
              user_id: userId as string,
              characterName,
              characterImageUrl,
            })
          );
        }
        handleRedirect(chatSessionId, characterId);
      } else {
        reportEvent({
          eventName: 'feErrorScreen',
          userId: userId as string,
          age: age,
          gender: gender,
          extraData: { error: `Session API Fail` },
        });
        dispatch(setError({ showErrorScreen: true }));
      }
    };
    if (chatCarouselData.length) {
      const isChatPresent = chatCarouselData.filter(
        (chat) => chat.characterId === charId
      );
      if (!isChatPresent.length) {
        startCharacterChat(true);
      } else {
        handleRedirect(
          isChatPresent[0].chatSessionId,
          isChatPresent[0].characterId
        );
      }
    } else {
      startCharacterChat();
    }
  };
  useEffect(() => {
    if (userId && (!chatId || !chatDataFetched)) {
      fetchData();
    }
  }, [userId, chatId, email]);
  useEffect(() => {
    if (isNextPageAvailable && pageCursor) {
      setTimeout(() => {
        document
          .getElementById(isDesktop() ? 'recentChats' : 'scrollableContainer')
          ?.addEventListener('scroll', trackScrolling);
      }, 100);
    } else {
      document
      .getElementById(isDesktop() ? 'recentChats' : 'scrollableContainer')
        ?.removeEventListener('scroll', trackScrolling);
    }
    return () => {
      document
      .getElementById(isDesktop() ? 'recentChats' : 'scrollableContainer')
        ?.removeEventListener('scroll', trackScrolling);
    };
  }, [isNextPageAvailable, pageCursor]);
  useEffect(() => {
    if (error) {
      reportEvent({
        eventName: 'feErrorScreen',
        userId: userId as string,
        age: age,
        gender: gender,
        extraData: { error: `Chat History API: ${error}` },
      });
      dispatch(setError({ showErrorScreen: true }));
      dispatch(setChatsGenerated(true));
      showLoader(false);
    } else if (responseData && responseData.status === 'Success') {
      setDataStatus(true);
      const { body, pageInfo } = responseData;
      if (!body.length) {
        if (fetchInProgress.current) {
          setNextPage(false);
          setPageCursor(null);  
        } else {
          setEmptyHistory(true);
          dispatch(setChatHistory([]));
          dispatch(setChatsGenerated(true));
        }
      } else {
        setEmptyHistory(false);
        dispatch(setChatHistory(fetchInProgress.current ? [...chatHistory, ...body] : body));
        dispatch(setChatsGenerated(true));
        if (pageInfo.cursor && pageInfo.hasNextPage) {
          setNextPage(true);
          setPageCursor(pageInfo.cursor);
        } else {
          setNextPage(false);
          setPageCursor(null);
        }
      }
      showLoader(false);
    }
  }, [responseData, error]);
  return (
    <div
      className={`${isDesktop() ? styles.desktopChats : ''} ${
        styles.chatHistory
      }`}
    >
      {isHistoryEmpty && (
        <ErrorScreen
          image={emptyResults}
          alt="logo"
          title={'youHavent'}
          subTitle={'noChatSelected'}
          btnText={'explore'}
          action={handleButonClick}
          btnVariant={'blueExplore'}
          imageSpec={83}
          className={'nochats'}
        />
      )}
      {chatHistory.length ? (
        <ul className={styles.chatHistoryList}>
          {chatHistory.map((chat) => {
            return (
              <li
                key={chat.characterId}
                onClick={() => startChat(chat.characterId)}
                className={chatId === chat.chatSessionId ? styles.active : ''}
              >
                <div className={styles.imageContainer}>
                  <Image
                    src={chat.characterImageUrl ? smallIconUrl(chat.characterImageUrl) : avatarIcon}
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{ width: '100%', height: 'auto' }}
                    alt="avatar"
                  />
                </div>
                <div className={styles.chatDetail}>
                  <div className={styles.userDetail}>
                    <p className={styles.charName}>{chat.characterName}</p>
                    <p className={styles.time}>
                      {getTime(chat.updatedAt?._seconds)}
                    </p>
                  </div>
                  <p
                    className={styles.lastMessage}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(chat.lastMessage as string),
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      ) : null}
      {((isLoading && !isDesktop()) || isLoader) && (
        <div
          className={`${styles.loadingContainer} ${
            fetchInProgress.current
              ? ''
              : isLoader
              ? `${styles.fullLoader} ${styles[orientation as string]}`
              : `${styles.fullScreen} ${styles[orientation as string]}`
          }`}
        >
          <Loader imageSpec={fetchInProgress.current ? 21 : 48} />
        </div>
      )}
    </div>
  );
};

export default Chat;
