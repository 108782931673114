import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import redirectIcon from '/public/static/assets/images/redirect.png';
import girl from '/public/static/assets/images/girl.webp';
import logo from '/public/static/assets/images/logo.png';
import discordText from '/public/static/assets/images/discordText.svg';
import Image from 'next/image';
import { getFeDeviceDetails, isDesktop } from '@/utils/device';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import {
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';
import { reportEvent } from '@/utils/api';
import { RootState } from '@/lib/store';
import Button from '../Button';

const DiscordPopup: React.FC<{
  closeDiscordPopup: () => void;
}> = ({ closeDiscordPopup }) => {
  const t = useTranslations();
  const { id, age, gender } = useAppSelector(
    (state: RootState) => state.user.userData
  );
  const dispatch = useAppDispatch();
  const openDiscord = () => {
    reportEvent({
      eventName: 'feDiscordLinkClicked',
      userId: id as string,
      age,
      gender,
      deviceType: getFeDeviceDetails().feDeviceType,
    });
    window.open('https://discord.gg/qdJunSRckP', '_blank');
  };
  const copyDiscordLink = () => {
    reportEvent({
      eventName: 'feCopyLinkClicked',
      userId: id as string,
      age,
      gender,
      deviceType: getFeDeviceDetails().feDeviceType,
      extraData: { source: 'discord' },
    });
    const el = document.createElement('textarea');
    el.value = 'https://discord.gg/qdJunSRckP';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    dispatch(setErrorToastMessage(t('copiedToClipboard')));
    setTimeout(() => {
      dispatch(setErrorToast({ showErrorToast: true }));
    }, 100);
  };
  return (
    <div className={styles.discordPopup}>
      <div className={styles.discordPopupContent}>
        <p className={styles.crossIcon} onClick={closeDiscordPopup} />
        <div className={styles.contentHeader}>
          <Image
            src={girl}
            alt="wsup"
            className={styles.girl}
          />
          <div className={styles.iconsContainer}>
            <Image src={logo} alt="wsup" className={styles.logo} />
            <p className={styles.helloText}>{t('hello')}</p>
            <Image
              src={discordText}
              alt="discord"
              className={styles.discordText}
            />
          </div>
        </div>
        <div
          className={`${styles.subContent} ${isDesktop() ? '' : styles.mobile}`}
        >
          <div className={styles.titleSection}>
            <p className={styles.titleContainer}>
              <span className={styles.title}>{t('joinTheParty')}</span>
              <span className={styles.emoji}>&#127881;</span>
            </p>
            <p className={styles.subTitle}>
              {t.rich('weAreOndiscord', {
                results: (chunks) => <span>{chunks}</span>,
              })}
            </p>
          </div>
          <div
            className={`${styles.joinButton} ${styles.discord}`}
            onClick={openDiscord}
          >
            <p>{t('joinServer')}</p>
            <Image alt="redirect" src={redirectIcon} />
          </div>
        </div>
        <div
          className={`${styles.subContent} ${styles.invite} ${isDesktop() ? '' : styles.mobile
            }`}
        >
          <div className={styles.titleSection}>
            <p className={styles.titleContainer}>
              <span className={styles.title}>{t('inviteYourFriends')}</span>
            </p>
            <p
              className={styles.subTitle}
              dangerouslySetInnerHTML={{ __html: t('wantToshare') }}
            />
          </div>
          <Button
            key='copylink'
            action={copyDiscordLink}
            title='copylink'
            variant={'outline'}
          />
        </div>
      </div>
    </div>
  );
};

export default DiscordPopup;
