import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import styles from './index.module.scss';
import { RootState } from '@/lib/store';
import { updateNSFWValue } from '@/lib/features/userSlice';
import { useTranslations } from 'next-intl';
import { reportEvent } from '@/utils/api';
import { getFeDeviceDetails } from '@/utils/device';

const NSFWSwitch: React.FC<{ className?: string }> = ({ className }) => {
  const { isNSFWEnabled } = useAppSelector((state: RootState) => state.user);
  const { id, age, gender } = useAppSelector(
    (state: RootState) => state.user.userData
  );
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const handleNSFWClick = () => {
    localStorage?.setItem('isNSFWEnabled', isNSFWEnabled ? 'false' : 'true');
    reportEvent({
      eventName: isNSFWEnabled ? 'feNsfwSwitchDisabled' : 'feNsfwSwitchEnabled',
      userId: id as string,
      deviceType: getFeDeviceDetails().feDeviceType,
      age,
      gender,
      extraData: { source: className ? 'search' : 'header' },
    });
    dispatch(updateNSFWValue(!isNSFWEnabled));
  };
  return (
    <div
      className={`${styles.nsfwSwitch} ${
        isNSFWEnabled ? styles.nsfwEnabled : ''
      } ${className ? styles[className] : ''}`}
    >
      <p>{t('nsfw')}</p>
      <p className={styles.switch} onClick={handleNSFWClick}>
        <span />
      </p>
    </div>
  );
};

export default NSFWSwitch;
