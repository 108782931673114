import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import styles from './index.module.scss';
import { RootState } from '@/lib/store';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Login from '../Login';
import { useTranslations } from 'next-intl';
import {
  setPostCharacterId,
  setShowLoginModal,
  showCreatePost,
  updateUserData,
} from '@/lib/features/userSlice';
import loginImage from '/public/static/assets/images/loginImage.webp';
import copyIcon from '/public/static/assets/images/copyIcon.png';
import copyHoverIcon from '/public/static/assets/images/copyIconHover.png';
import thumbnail from '/public/static/assets/images/thumbnail.png';
import uploadImage from '/public/static/assets/images/uploadImage.svg';
import videoThumbnail from '/public/static/assets/images/videoThumbnail.png';
import Image from 'next/image';
import { reportEvent } from '../../utils/api';
import Button from '../Button';
import { useRouter } from 'next/navigation';
import { formatViewCount, isValidFileType, smallIconUrl } from '@/utils';
import TextBoxSection from '../TextBoxSection';
import useApi from '@/hooks/useApi';
import {
  setError,
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';
import { getFeDeviceDetails, isDesktop } from '@/utils/device';
import ShareOptions from '../ShareOptions';

const CreatePost: React.FC = () => {
  const t = useTranslations();
  const [uploadedImage, setUploadedImage] = useState<null | string>(null);
  const uploadedFileSize = useRef<number | null>(null);
  const uploadedFileExtension = useRef<string | null>(null);
  const {
    isSignedIn,
    showVerificationScreen,
    videoQuotaLeft,
    postUploaded,
    publishedFeedId,
    publishedPostTitle,
  } = useAppSelector((state: RootState) => state.user.userData);
  const { characters, postCharacterId } = useAppSelector(
    (state: RootState) => state.user
  );
  const isSelectedCharacter = characters.find(
    (char) => char.characterId === postCharacterId
  );
  const [selectedCharacter, setSelectedCharacter] = useState(
    isSelectedCharacter ? isSelectedCharacter : characters[0]
  );
  const [isActive, setIsActive] = useState(false);
  const [description, setDescription] = useState('');
  const dispatch = useAppDispatch();
  const [signUpFlow, setSignUpFlow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [activeMedia, setActiveMedia] = useState('image');
  const { id, age, gender } = useAppSelector(
    (state: RootState) => state.user.userData
  );
  const { responseData, fetchData, error } = useApi<{
    status: string;
    body: {
      userPostFeedId: string;
    };
  }>('POST', `/ai/api/char/v1/userFeedPost`, {
    mediaType: activeMedia,
    description,
    characterId: selectedCharacter?.characterId as string,
    referenceImage: uploadedImage,
  });
  const router = useRouter();
  const handleCrossClick = () => {
    dispatch(showCreatePost(false));
  };
  const publishPost = () => {
    setLoading(true);
    fetchData();
    dispatch(updateUserData({ postInProgress: true }));
  };
  const createCharacter = (source: string) => {
    reportEvent({
      eventName: `feCharCreateClick`,
      userId: id as string,
      age,
      gender,
      extraData: { source },
    });
    dispatch(showCreatePost(false));
    router.push('/dashboard/create');
  };
  const viewPost = () => {
    reportEvent({
      eventName: `feViewPostClicked`,
      userId: id as string,
      age,
      gender,
    });
    router.push(`/dashboard/feeds/${publishedFeedId}`);
    dispatch(showCreatePost(false));
  };
  const copyLink = () => {
    reportEvent({
      eventName: 'feCopyLinkClicked',
      userId: id as string,
      age,
      gender,
      deviceType: getFeDeviceDetails().feDeviceType,
      extraData: { source: 'newPostPublished' },
    });

    const el = document.createElement('textarea');
    el.value = `https://${window.location.hostname}/sup-ai/dashboard/feeds/${publishedFeedId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    dispatch(setErrorToastMessage(t('copiedToClipboard')));
    setTimeout(() => {
      dispatch(setErrorToast({ showErrorToast: true }));
    }, 100);
  };
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files;
    const reader = new FileReader();
    if (file && file.length) {
      const fileName = file.item(0)?.name;
      const dotIndex = fileName?.lastIndexOf('.');
      if (dotIndex) {
        let fileExtension = fileName
          ?.substr(dotIndex + 1, fileName.length)
          .toLowerCase();
        if (!isValidFileType(fileExtension as string)) {
          dispatch(setErrorToastMessage(t('invalidFileTypeError')));
          setTimeout(() => {
            dispatch(setErrorToast({ showErrorToast: true }));
          }, 100);
          setUploadedImage(null);
          return;
        } else {
          uploadedFileExtension.current = fileExtension as string;
        }
      } else {
        dispatch(setErrorToastMessage(t('invalidFileTypeError')));
        setTimeout(() => {
          dispatch(setErrorToast({ showErrorToast: true }));
        }, 100);
        setUploadedImage(null);
        return;
      }
      const fileSize = file.item(0)?.size;
      if (fileSize) {
        const fileSizeinMb = Math.round(fileSize / 1024);
        if (fileSizeinMb >= 8192) {
          dispatch(setErrorToastMessage(t('fileSizeError')));
          setTimeout(() => {
            dispatch(setErrorToast({ showErrorToast: true }));
          }, 100);
          setUploadedImage(null);
          return;
        } else {
          uploadedFileSize.current = fileSizeinMb;
        }
      }
      reader.addEventListener('load', function (event) {
        const base64Image = event.target?.result;
        setUploadedImage(base64Image as string);
      });
      reader.readAsDataURL(file[0]);
    }
  };
  useEffect(() => {
    if (!isSignedIn) {
      dispatch(setShowLoginModal({
        showLoginModal: true,
        loginModalSource: 'createPost',
      }));
    }
    return () => {
      dispatch(setPostCharacterId(''));
      dispatch(updateUserData({ postUploaded: false }));
    };
  }, []);
  useEffect(() => {
    if (responseData && responseData.status === 'Success') {
      setLoading(false);
      dispatch(showCreatePost(false));
      dispatch(
        updateUserData({ userPostFeedId: responseData?.body?.userPostFeedId })
      );
    } else if (error) {
      setLoading(false);
      dispatch(updateUserData({ postInProgress: false }));
      if (error && error.status === t('FailureUnauthorized')) {
        reportEvent({
          eventName: 'feErrorScreen',
          userId: id as string,
          age: age,
          gender: gender,
          extraData: { error: `Create Post API: ${error}` },
        });
        dispatch(setError({ showErrorScreen: true }));
      } else if (error && error.status === 'FailureUploadPostInProgress') {
        dispatch(setErrorToastMessage(t('postCreationInProgress')));
        setTimeout(() => {
          dispatch(setErrorToast({ showErrorToast: true }));
        }, 100);
      } else if (error && error.status === 'FailureRateLimitExceeded') {
        dispatch(setErrorToastMessage(t('exceededLimitError')));
        setTimeout(() => {
          dispatch(setErrorToast({ showErrorToast: true }));
        }, 100);
      } else {
        dispatch(
          setErrorToastMessage(
            t(
              selectedCharacter?.isPrivate
                ? 'privateCharPostFailed'
                : 'unableToUploadPost'
            )
          )
        );
        setTimeout(() => {
          dispatch(setErrorToast({ showErrorToast: true }));
        }, 100);
      }
    }
  }, [responseData, error]);
  useEffect(() => {
    if (isSignedIn && !signUpFlow && characters.length) {
      if (!postUploaded) {
        reportEvent({
          eventName: `feCreatePostScreenVisible`,
          userId: id as string,
          age,
          gender,
        });
      } else {
        reportEvent({
          eventName: `fePostPublishedScreenVisible`,
          userId: id as string,
          age,
          gender,
        });
      }
    }
  }, [characters, postUploaded, isSignedIn, signUpFlow]);
  return isLoading ? null : (
    <div
      className={`${isDesktop() ? styles.desktopCreatePost : ''} ${
        styles.createPost
      } ${isDesktop() && !isSignedIn && !signUpFlow ? styles.showLogin : ''}`}
    >
      {isSignedIn && !signUpFlow && (
        <div className={styles.contentSection}>
          <div className={styles.titleHeader}>
            <p>{postUploaded ? t('postReady') : t('createPost')}</p>
            <p className={styles.crossIcon} onClick={handleCrossClick} />
          </div>
          {postUploaded ? (
            <div className={styles.postUploadedSection}>
              <div className={styles.postUploaded}>
                <p
                  className={styles.postLink}
                >{`https://${window.location.hostname}/sup-ai/dashboard/feeds/${publishedFeedId}`}</p>
                <Image
                  src={copyIcon}
                  alt="copy"
                  onClick={copyLink}
                  onMouseEnter={(e) =>
                    (e.currentTarget.src = copyHoverIcon.src)
                  }
                  onMouseLeave={(e) => (e.currentTarget.src = copyIcon.src)}
                />
                <Button
                  title="viewPost"
                  variant="createChar"
                  action={viewPost}
                />
              </div>
              <div className={styles.sharePostSection}>
                <p className={styles.sharePost}>{t('shareWithFriends')}</p>
                <ShareOptions
                  postFeedId={publishedFeedId || ''}
                  postTitle={publishedPostTitle || ''}
                  authorName={selectedCharacter?.name as string}
                />
              </div>
            </div>
          ) : characters.length ? (
            <>
              <div className={styles.createPostSection}>
                <div className={styles.titleSection}>
                  <p>{t('postAs')}</p>
                  <p onClick={() => createCharacter('createPostNewCharacter')}>
                    +{t('newCharacter')}
                  </p>
                </div>
                <div
                  className={styles.selectedCharacter}
                  onClick={() => setIsActive(!isActive)}
                >
                  <div className={styles.charDescription}>
                    <Image
                      src={smallIconUrl(selectedCharacter?.characterImageUrl)}
                      alt="Char Image"
                      width={36}
                      height={36}
                    />
                    <p className={styles.charDetails}>
                      <span>{selectedCharacter?.name}</span>
                      <span>
                        {t('chats', {
                          number: formatViewCount(
                            selectedCharacter?.chatSessionCount
                          ),
                        })}
                      </span>
                    </p>
                  </div>
                  {characters.length > 1 && (
                    <p
                      className={`${styles.caret} ${
                        isActive ? styles.active : ''
                      }`}
                    />
                  )}
                  {isActive && characters.length > 1 && (
                    <ul>
                      {characters.map((char) => (
                        <li
                          className={`${styles.charDescription} ${
                            char.characterId === selectedCharacter?.characterId
                              ? styles.active
                              : ''
                          }`}
                          key={char.characterId}
                          onClick={() => setSelectedCharacter(char)}
                        >
                          <Image
                            src={smallIconUrl(char.characterImageUrl)}
                            alt="Char Image"
                            width={36}
                            height={36}
                          />
                          <p className={styles.charDetails}>
                            <span>{char.name}</span>
                            <span>
                              {t('chats', {
                                number: formatViewCount(char.chatSessionCount),
                              })}
                            </span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <TextBoxSection
                  title="postDescription"
                  required={true}
                  feature="post_description"
                  name={selectedCharacter?.name}
                  updateValue={setDescription}
                  value={description}
                  maxlength={200}
                  commonClass={'createPost'}
                  linkText={'rephraseWithAI'}
                  action={'rephrase'}
                  placeholder={
                    'Describe your character’s big moment! What’s happening in their world? Example: Your character watches the vibrant sunset, feeling a surge of joy, thinking how moments like this make life extraordinary.'
                  }
                />
                {/* <div className={styles.imageUpload}>
                  <p className={styles.mediaTitle}>{t('referenceImage')}</p>
                  <input
                    type="file"
                    id={`image-upload`}
                    accept="image/jpg, image/jpeg, image/png, image/webp, .heic, .heif, .avif"
                    hidden
                    onChange={handleImageUpload}
                  />
                  {!uploadedImage && (
                    <label
                      className={styles.imagelabel}
                      htmlFor={`image-upload`}
                    >
                      <Image
                        src={uploadImage}
                        alt="upload"
                        className={styles.upload}
                      />
                      <p>{t('uploadReferenceImage')}</p>
                    </label>
                  )}
                  {uploadedImage && (
                    <div className={styles.uploadedImageSection}>
                      <div className={styles.uploadedImage}>
                        <Image
                          src={uploadedImage}
                          alt="ref Image"
                          width={58}
                          height={58}
                        />
                        <ul>
                          <li>{uploadedFileExtension.current}</li>
                          <li>{uploadedFileSize.current} KB</li>
                        </ul>
                      </div>
                      <div className={styles.btnContainer}>
                        <Button
                          title="remove"
                          variant="outline"
                          action={() => setUploadedImage(null)}
                        />
                        <label htmlFor={`image-upload`}>
                          <div>{t('change')}</div>
                        </label>
                      </div>
                    </div>
                  )}
                </div> */}
                <p className={styles.mediaTitle}>{t('mediaType')}</p>
                <ul className={styles.mediaList}>
                  <li
                    className={`${
                      activeMedia === 'image' ? styles.active : ''
                    }`}
                    onClick={() => setActiveMedia('image')}
                  >
                    <Image src={thumbnail} alt="image" />
                    <p>{t('image')}</p>
                  </li>
                  <li
                    className={`${
                      activeMedia === 'video' ? styles.active : ''
                    } ${videoQuotaLeft === 0 ? styles.disabled : ''}`}
                    onClick={() => setActiveMedia('video')}
                  >
                    <Image src={videoThumbnail} alt="video" />
                    <p>{t('video')}</p>
                    <span className={styles.quotaText}>
                      {t('quotaLeft', { quotleft: videoQuotaLeft })}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  title="cancel"
                  variant="outline"
                  action={handleCrossClick}
                />
                <Button
                  title="createPost"
                  variant="createChar"
                  action={publishPost}
                  disabled={!description.trim().length}
                />
              </div>
            </>
          ) : (
            <div className={styles.createCharacterSection}>
              <Image src={loginImage} alt="Char Image" />
              <p className={styles.headerText}>{t('createToPost')}</p>
              <p className={styles.subHeaderText}>{t('youCanShare')}</p>
              <Button
                variant="createChar"
                title={'createCharacter'}
                action={() => createCharacter('createPost')}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreatePost;
