'use client';

import ErrorToast from '@/components/ErrorToast';
import {
  setError,
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { useTranslations } from 'next-intl';
import styles from './layout.module.scss';
import { useEffect, useRef, useState } from 'react';
import useApi from '@/hooks/useApi';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CharactersData } from './types';
import Sidepanel from '@/components/Sidepanel';
import { isDesktop } from '@/utils/device';
import {
  setCharacters,
  showCreatePost,
  updateUserData,
} from '@/lib/features/userSlice';
import FloatingNotifications from '@/components/Notification/FloatingNotifications';
import Header from '@/components/Header';
import CreatePost from '@/components/CreatePost';
import { doc, getFirestore, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { reportBugsnagError, reportEvent } from '@/utils/api';
import { NotifiableError } from '@bugsnag/js';
import SharePost from '@/components/SharePost';
import Footer from '@/components/Footer';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { setCharData, setCharIds } from '@/lib/features/charSlice';
import DeleteModal from '@/components/DeleteModal';
import Login from '@/components/Login';
import { imageLoaderWithPrefix } from '@/utils';

type ICharResponse = {
  status: string;
  body: CharactersData[];
  pageInfo: {
    hasNextPage: boolean;
    cursor: string;
  };
};

type Characters = {
  characterId: string;
  name: string;
  characterImageUrl: string;
  chatSessionCount: number;
  rank?: number;
};

type IProfileResponse = {
  status: string;
  body: {
    userMetadata: {
      nickName: string;
      avatarUrl: string;
      aboutYou: string;
      userUpdatedProfile: boolean;
    };
    characters: Characters[];
  } | null;
};

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { showErrorToast, showErrorScreen } = useAppSelector(
    (state: RootState) => state.device.deviceData
  );
  const {
    id: userDataId,
    userPostFeedId,
    videoQuotaLeft,
    age,
    gender,
    isNewUser,
    emailUpdated,
    userVerified,
    userCountryCode
  } = useAppSelector((state: RootState) => state.user.userData);
  const { charactersData } =
    useAppSelector((state: RootState) => state.charSlice);
  const { charId, feedId } = useParams();
  const searchParams = useSearchParams();
  const chatId = searchParams.get('chatId');
  const activeCategory = searchParams.get('category');
  const selectedCategory = useRef(
    activeCategory
      ? String(activeCategory.toLowerCase()).charAt(0).toUpperCase() +
          String(activeCategory).slice(1)
      : 'Recommended'
  );
  const { showShareModal } = useAppSelector(
    (state: RootState) => state.user.sharePost
  );
  const { createPost: isCreatePost, showDeleteConfirmation, showLoginModal } = useAppSelector(
    (state: RootState) => state.user
  );
  const pathName = usePathname();
  const dispatch = useAppDispatch();
  const [domLoaded, setDomLoaded] = useState(false);
  const chatDetails = useAppSelector(
    (state: RootState) =>
      state.chat.chatData.filter((chat) => chat.chatSessionId === chatId)[0]
  );
  const characterImageUrl = chatDetails?.characterImageUrl;
  const { isNSFWEnabled } = useAppSelector((state: RootState) => state.user);
  const t = useTranslations();
  const {
    responseData: charResponseData,
    error: charError,
    fetchData: fetchCharData,
  } = useApi<ICharResponse>(
    'GET',
    `/ai/api/char/v1/characters?age=${age}&gender=${gender}&isNsfw=${isNSFWEnabled}&isNewUser=${isNewUser}&category=recommended`
  );
  const {
    responseData: profileResponseData,
    fetchData: fetchProfileData,
    error: profileError,
  } = useApi<IProfileResponse>('GET', `/ai/api/char/v1/profile`);

  useEffect(() => {
    if (profileError) {
      reportEvent({
        eventName: 'feErrorScreen',
        userId: userDataId as string,
        age: age,
        gender: gender,
        extraData: { error: `Profile API: ${profileError}` },
      });
      dispatch(setError({ showErrorScreen: true }));
    } else if (
      profileResponseData &&
      profileResponseData.status === 'Success'
    ) {
      const { body } = profileResponseData;
      if (body) {
        const {
          userMetadata: { nickName, aboutYou, avatarUrl },
          characters,
        } = body;
        dispatch(
          updateUserData({
            name: nickName,
            description: aboutYou,
            avatar: avatarUrl,
          })
        );
        dispatch(setCharacters(characters));
      }
    }
  }, [profileResponseData, profileError]);
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  useEffect(() => {
    if (charError) {
      reportEvent({
        eventName: 'feErrorScreen',
        userId: userDataId as string,
        age: age,
        gender: gender,
        extraData: { error: `Characters API ${charError}` },
      });
      dispatch(setError({ showErrorScreen: true }));
    } else if (charResponseData && charResponseData.status === 'Success') {
      const {
        body,
        pageInfo: { hasNextPage, cursor },
      } = charResponseData;
      if (body.length) {
        dispatch(
          setCharData({
            category: 'Recommended',
            data: { data: body as CharactersData[], cursor, hasNextPage },
          })
        );
        let charIds: Record<string, boolean> = {};
        body.forEach((char) => {
          const { id } = char;
          charIds[id] = true;
        });
        dispatch(setCharIds({ charIds, selectedCategory: 'Recommended' }));
      } else {
        dispatch(
          setCharData({
            category: 'Recommended',
            data: { data: [], cursor, hasNextPage },
          })
        );
      }
      fetchProfileData();
    }
  }, [charResponseData, charError]);
  useEffect(() => {
    if (userDataId && userVerified) {
      fetchCharData();
    }
  }, [userDataId, userVerified]);
  useEffect(() => {
    if (userDataId && userVerified && emailUpdated && (charactersData[selectedCategory.current]?.data.length ||
      charactersData[selectedCategory.current]?.isDataFetched)) {
      fetchProfileData();
    }
  }, [userDataId, userVerified, emailUpdated]);
  useEffect(() => {
    if (userPostFeedId && userCountryCode !== 'VN') {
      try {
        const db = getFirestore();
        let unsubscribe: Unsubscribe;
        const feedRef = doc(db, 'CharUserPostFeeds', userPostFeedId);
        unsubscribe = onSnapshot(feedRef, (snapshot) => {
          if (snapshot.exists()) {
            const { publishedFeedId, postTitle, type, status } =
              snapshot.data();
            if (status === 'PUBLISHED') {
              if (type === 'video') {
                dispatch(
                  updateUserData({
                    videoQuotaLeft: (videoQuotaLeft || 10) - 1,
                  })
                );
              }
              dispatch(
                updateUserData({
                  publishedFeedId,
                  postInProgress: false,
                  postUploaded: true,
                  userPostFeedId: null,
                  publishedPostTitle: postTitle,
                })
              );
              dispatch(showCreatePost(true));
              unsubscribe();
            } else if (status === 'FAILED') {
              dispatch(
                updateUserData({
                  postInProgress: false,
                  userPostFeedId: null,
                })
              );
              dispatch(setErrorToastMessage(t('unableToUploadPost')));
              setTimeout(() => {
                dispatch(setErrorToast({ showErrorToast: true }));
              }, 100);
              unsubscribe();
            }
          } else {
            dispatch(setErrorToastMessage(t('unableToUploadPost')));
            setTimeout(() => {
              dispatch(setErrorToast({ showErrorToast: true }));
            }, 100);
            reportBugsnagError(
              new Error(
                `Snapshot does not exist: ${userPostFeedId}`
              ) as NotifiableError,
              userDataId
            );
          }
        });
      } catch (error) {
        dispatch(setErrorToastMessage(t('unableToUploadPost')));
        setTimeout(() => {
          dispatch(setErrorToast({ showErrorToast: true }));
        }, 100);
        reportBugsnagError(
          new Error(
            `Error fetching database: ${userPostFeedId}`
          ) as NotifiableError,
          userDataId
        );
      }
    }
  }, [userPostFeedId]);
  return (
    <section className={styles.desktopLayout}>
      {domLoaded && !showErrorScreen && (!chatId || isDesktop()) && userVerified && userDataId && <Header />}
      {domLoaded && (
        <div
          className={`${isDesktop() ? styles.desktopContent : ''} ${styles.contentSection
            } ${!isDesktop() && chatId ? styles.chatContent : ''}`}
            style={characterImageUrl && chatId && !isDesktop() ? {
              background: `url(${imageLoaderWithPrefix({ src: characterImageUrl, width: 640, quality: 80 })}) top / cover no-repeat`,
            } : {}}
        >
          {isDesktop() && <Sidepanel />}
          {!isDesktop() &&
            !chatId &&
            !charId &&
            !feedId &&
            !pathName.includes('/create') && userVerified && userDataId && <Footer />}
          {children}
          {showErrorToast && <ErrorToast />}
          <FloatingNotifications />
          {isCreatePost && userCountryCode !== 'VN' && <CreatePost />}
          {showShareModal && <SharePost />}
          {showDeleteConfirmation && <DeleteModal />}
          {showLoginModal && <Login />}
        </div>
      )}
    </section>
  );
}
