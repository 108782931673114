import Image from 'next/image';
import splashLogo from '/public/static/assets/images/splashLogo.webp';
import loginImage from '/public/static/assets/images/loginModalImage.webp';
import emailSentImage from '/public/static/assets/images/emailFingerprint.webp';
import googleImg from '/public/static/assets/images/google.png';
import arrowImg from '/public/static/assets/images/arrow.png';
import styles from './index.module.scss';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import {
  linkWithCredential,
  getAuth,
  sendEmailVerification,
  GoogleAuthProvider,
  linkWithPopup,
  signInWithCredential,
  User,
  sendSignInLinkToEmail,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import {
  setErrorToast,
} from '@/lib/features/deviceSlice';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { setLoginVisible, setShowLoginModal, showCreatePost, updateUserData } from '@/lib/features/userSlice';
import { login, reportEvent } from '@/utils/api';
import { RootState } from '@/lib/store';
import { getFeDeviceDetails, isDesktop } from '@/utils/device';
import Link from 'next/link';

const Login = () => {
  const t = useTranslations();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const {
    email: emailId,
    id,
    age,
    gender,
    showVerificationScreen,
  } = useAppSelector((state: RootState) => state.user.userData);
  const {
    loginModalSource
  } = useAppSelector((state: RootState) => state.user);
  const router = useRouter();
  const { createPost } = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const closeLogin = () => {
    dispatch(setShowLoginModal({
      showLoginModal: false,
      loginModalSource: '',
    }));
    if (createPost) {
      dispatch(showCreatePost(false));
    }
  };
  const sendMail = () => {
    setLoading(true);
    const auth = getAuth();
    const url = new URL(window.location.href);
    url.searchParams.set('email', email as string);
    const actionCodeSettings = {
      url: url.href,
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem('emailForSignIn', email);
      setLoading(false);
      setEmailSent(true);
    })
    .catch((error) => {
      setLoading(false);
    });
  };
  const handleLogin = async (user: User) => {
    const token = await user.getIdToken();
    const email = user.email;
    const isEmailVerified = user.emailVerified;
    login(token, id as string).then((res) => {
      if (res === 'Fail') {
        setLoading(false);
        dispatch(setErrorToast({ showErrorToast: true }));
      } else {
        setLoading(false);
        dispatch(
          updateUserData({
            showVerificationScreen: !isEmailVerified,
            email: email || '',
            isSignedIn: true,
            emailUpdated: true,
          })
        );
        closeLogin();
      }
    });
  };
  const loginWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    if (window.AndroidInterface) {
      window.AndroidInterface.initiateGoogleLogin();
      return;
    }
    if (auth.currentUser) {
      setPersistence(auth, browserLocalPersistence).then(() => {
        if (auth.currentUser) {
          linkWithPopup(auth.currentUser, provider)
            .then((result) => {
              handleLogin(result.user);
            })
            .catch(async (error) => {
              if (
                error.code === 'auth/credential-already-in-use' ||
                error.code === 'auth/email-already-in-use'
              ) {
                const credentials =
                  GoogleAuthProvider.credentialFromError(error);
                if (credentials) {
                  try {
                    const result = await signInWithCredential(
                      auth,
                      credentials
                    );
                    handleLogin(result.user);
                  } catch (e) {
                    dispatch(setErrorToast({ showErrorToast: true }));
                  }
                } else {
                  dispatch(setErrorToast({ showErrorToast: true }));
                }
              } else {
                dispatch(setErrorToast({ showErrorToast: true }));
              }
            });
        }
      });
    }
  };
  const addSignInSuccessHandler = () => {
    window.AndroidInterface.onGoogleSignInSuccess = async (idToken: string) => {
      const auth = getAuth();
      const { currentUser } = auth;
      if (idToken && currentUser) {
        const credentials = GoogleAuthProvider.credential(idToken);
        await linkWithCredential(currentUser, credentials)
          .then((result) => {
            handleLogin(result.user);
          })
          .catch(async (error) => {
            if (
              error.code === 'auth/credential-already-in-use' ||
              error.code === 'auth/email-already-in-use'
            ) {
              if (credentials) {
                try {
                  const result = await signInWithCredential(auth, credentials);
                  handleLogin(result.user);
                } catch (e) {
                  dispatch(setErrorToast({ showErrorToast: true }));
                }
              } else {
                dispatch(setErrorToast({ showErrorToast: true }));
              }
            } else {
              dispatch(setErrorToast({ showErrorToast: true }));
            }
          });
      } else {
        dispatch(setErrorToast({ showErrorToast: true }));
      }
    };
  };
  useEffect(() => {
    if (id) {
      reportEvent({
        eventName: 'feLoginScreenShown',
        userId: id as string,
        deviceType: getFeDeviceDetails().feDeviceType,
        extraData: { source: loginModalSource as string },
        age,
        gender,
      });
    }
  }, [id]);
  useEffect(() => {
    if (showVerificationScreen) {
      const auth = getAuth();
      const { currentUser } = auth;
      if (currentUser) {
        sendEmailVerification(currentUser);
      }
    }
  }, [showVerificationScreen]);
  useEffect(() => {
    if (window && window.AndroidInterface) {
      addSignInSuccessHandler();
    }
    dispatch(setLoginVisible(true));

    return () => {
      dispatch(setLoginVisible(false));
    };
  }, []);
  return (
    <div
      className={`${styles.login} ${isDesktop() ? '' : styles.mobileLogin}`}
    >
      <div className={styles.loginContent}>
        <p className={styles.cross} onClick={closeLogin} />
        <div
          className={`${styles.signupSection} ${
            isDesktop() ? '' : styles.mobileSignup
          }`}
        >
          <div className={styles.signupInfo}>
            <Image src={splashLogo} alt="logo" width={58} height={56} className={styles.splashLogo} />
            <div className={styles.signupTextSection}>
              <p className={styles.title}>{t('bringYourImagination')}</p>
              <p className={styles.subTitle}>{t('loginNowTo')}</p>
            </div>
            <div className={styles.loginOptions}>
              {isLoading ? (
                <p className={styles.loader} />
              ) : emailSent ? (
                <div className={styles.msgSentSection}>
                  <Image src={emailSentImage} alt="email" width={100} height={100} />
                  <p>{t('emailSentMessage')}</p>
                </div>
              ) : (
                <>
                  <div className={styles.emailInput}>
                    <input
                      className={styles.userInput}
                      type='text'
                      placeholder={t('emailAddress')}
                      value={email}
                      onInput={(e) => setEmail(e.currentTarget.value)}
                      onKeyDown={(e) => e.key === 'Enter' && email && sendMail()}
                    />
                    <Image src={arrowImg} alt="arrow" className={styles.enterButton} onClick={email ? sendMail : undefined} style={!email ? { opacity: 0.5, cursor: 'auto' } : {}} />
                  </div>
                  <p className={styles.divider}>
                    <span />
                    <span>{t('or')}</span>
                    <span />
                  </p>
                  <div className={styles.loginBtn} onClick={loginWithGoogle}>
                    <Image src={googleImg} alt="login" />
                    <p>{t('loginWithGoogle')}</p>
                  </div>
                  <div className={styles.terms}>
                    <span>{t('signInTerms')}</span>
                    <Link
                      target="_blank"
                      href={`${window.location.origin}/sup-ai/tnc?tab=termsOfUse`}
                    >
                      {t('termsService')}
                    </Link>
                    <span>{t('and')}</span>
                    <Link
                      target="_blank"
                      href={`${window.location.origin}/sup-ai/tnc?tab=privacyPolicy`}
                    >
                      {t('privacyPolicy')}
                    </Link>
                  </div>  
                </>
              )}
            </div>
          </div>
          <div className={styles.loginImageContainer}>
            {isDesktop() && (
                <Image
                src={loginImage}
                alt="login"
                className={`${styles.charLoginImage}`}
              />
            )}
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Login;
