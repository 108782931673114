import styles from './index.module.scss';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import feedImg from '/public/static/assets/images/feed.png';
import budsImg from '/public/static/assets/images/buds.png';
import createImg from '/public/static/assets/images/create.png';
import postImg from '/public/static/assets/images/post.png';
import Chat from '@/components/Chat';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { reportEvent } from '@/utils/api';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Link from 'next/link';
import { showCreatePost } from '@/lib/features/userSlice';
import {
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';

const Sidepanel: React.FC = () => {
  const router = useRouter();
  const { id, age, gender, postInProgress, userCountryCode, userVerified } =
    useAppSelector((state: RootState) => state.user.userData);
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const chatId = searchParams.get('chatId');
  const { createPost } = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const handleTabClick = (tab: string) => {
    reportEvent({
      eventName: `fe${tab}Click`,
      userId: id as string,
      age,
      gender,
    });
    dispatch(showCreatePost(false));
    router.push(`/dashboard/${tab === 'Explore' ? 'characters' : 'feeds'}`);
  };
  const handleCreateClick = () => {
    dispatch(showCreatePost(false));
    reportEvent({
      eventName: `feCharCreateClick`,
      userId: id as string,
      age,
      gender,
      extraData: { source: 'header' },
    });
    router.push('/dashboard/create');
  };
  const handleCreatePostClick = () => {
    reportEvent({
      eventName: `feCreatePostClick`,
      userId: id as string,
      age,
      gender,
      extraData: { source: 'sidepanel' },
    });
    if (postInProgress) {
      dispatch(setErrorToastMessage(t('postCreationInProgress')));
      setTimeout(() => {
        dispatch(setErrorToast({ showErrorToast: true }));
      }, 100);
      return;
    }
    dispatch(showCreatePost(true));
  };
  return (
    <section className={styles.sidePanel}>
      {id && userVerified ? (
        <>
          <ul className={styles.tabs}>
            <li
              className={pathName.indexOf('feeds') > -1 ? styles.active : ''}
              onClick={() => handleTabClick('Feed')}
            >
              <Image src={feedImg} alt="feed" />
              <p>{t('feed')}</p>
            </li>
            <li
              className={
                pathName.indexOf('characters') > -1 ? styles.active : ''
              }
              onClick={() => handleTabClick('Explore')}
            >
              <Image src={budsImg} alt="feed" />
              <p>{t('explore')}</p>
            </li>
            {userCountryCode !== 'VN' && (
              <li
                className={
                  pathName === '/dashboard/create' ? styles.active : ''
                }
                onClick={handleCreateClick}
              >
                <Image
                  alt="create"
                  src={postImg}
                  className={styles.createImg}
                />
                <p>{t('createCharacter')}</p>
              </li>
            )}
            {!postInProgress && userCountryCode !== 'VN' && (
              <li
                className={createPost ? styles.active : ''}
                onClick={handleCreatePostClick}
              >
                <Image
                  alt="create post"
                  src={createImg}
                  className={styles.createImg}
                />
                <p>{t('createPost')}</p>
              </li>
            )}
            {postInProgress && userCountryCode !== 'VN' && (
              <li>
                <p className={styles.loader} />
                <p>{t('creatingPost')}</p>
              </li>
            )}
          </ul>
          <div className={styles.recentChats} id="recentChats">
            <p className={styles.chatTitle}>{t('recentChats')}</p>
            <div className={styles.chatsSection}>
              <Chat chatId={chatId} />
            </div>
          </div>
          <div className={styles.tncFooter}>
            <div className={styles.externalLinks}>
              <Link
                target="_blank"
                href={`${window.location.origin}/sup-ai/tnc?tab=privacyPolicy`}
              >
                {t('privacyPolicy')}
              </Link>
              <Link
                target="_blank"
                href={`${window.location.origin}/sup-ai/tnc?tab=termsOfUse`}
              >
                {t('termsOfUse')}
              </Link>
            </div>
            <p className={styles.copyright}>{t('copyright')}</p>
          </div>
        </>
      ) : null}
    </section>
  );
};

export default Sidepanel;
