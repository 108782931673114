import styles from './index.module.scss';
import Image from 'next/image';
import arrowImg from '/public/static/assets/images/arrow.png';
import closeImg from '/public/static/assets/images/closePlain.png';
import retryImg from '/public/static/assets/images/retry.png';
import alertImg from '/public/static/assets/images/alert.png';
import { showCreatePost } from '@/lib/features/userSlice';
import { useTranslations } from 'next-intl';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { decrementUnreadCount } from '@/lib/features/notifSlice';
import { reportBugsnagError, reportEvent } from '@/utils/api';
import { RootState } from '@/lib/store';
import { getFeDeviceDetails } from '@/utils/device';
import { NotifiableError } from '@bugsnag/js';
import { NotificationData } from '..';
import { smallIconUrl } from '@/utils';

const NotificationRow: React.FC<{
  data: NotificationData;
  floating: boolean;
  className?: string;
  onClose?: () => void;
}> = ({ data, floating, className, onClose }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const { id, age, gender } = useAppSelector(
    (state: RootState) => state.user.userData
  );

  const createdTime = () => {
    const createdAtMinutes =
      (Date.now() - data.createdAt._seconds * 1000) / 1000 / 60;
    let createdAtHours: number;
    let noOfDays: number;
    if (createdAtMinutes > 60) {
      createdAtHours = createdAtMinutes / 60;
      if (createdAtHours > 24) {
        noOfDays = Math.floor(createdAtHours / 24);
        return `${noOfDays} d`;
      }
      return `${Math.floor(createdAtHours)} h`;
    }
    return `${Math.floor(createdAtMinutes)} m`;
  };

  const markNotificationRead = async () => {
    try {
      const db = getFirestore();
      const userId = getAuth().currentUser?.uid;
      const notificationsRef = doc(
        db,
        `CharNotifications/${userId}/notifications/${data.id}`
      );
      if (notificationsRef === null) return;

      await updateDoc(notificationsRef, {
        read: true,
      });
      dispatch(decrementUnreadCount());
    } catch (error) {}
  };

  useEffect(() => {
    if (!floating && !data.read) markNotificationRead();

    if (floating) {
      reportEvent({
        eventName: 'feNotificationFloatingDisplayed',
        userId: id as string,
        age,
        gender,
        deviceType: getFeDeviceDetails().feDeviceType,
        extraData: {
          notificationId: data.id,
        },
      });
    }
  }, []);

  const onClickHandler = () => {
    reportEvent({
      eventName: 'feNotificationClicked',
      userId: id as string,
      age,
      gender,
      deviceType: getFeDeviceDetails().feDeviceType,
      extraData: {
        notificationId: data.id,
        source: floating ? 'floating' : 'console',
      },
    });
    if (!data.read) markNotificationRead();
    if (data.ctaType === 'feed') router.push(`/dashboard/feeds/${data.ctaId}`);
    if (data.ctaType === 'postFailure') dispatch(showCreatePost(true));
    onClose && onClose();
  };

  return (
    <div
      className={`${floating ? styles.floatingNotificationRow : styles.notificationRow
        } ${className ? styles[className] : ''}`}
      onClick={onClickHandler}
    >
      <div className={styles.notificationInfo}>
        <Image
          alt="avatar"
          src={smallIconUrl(data.imageUrl)}
          width={48}
          height={48}
          className={styles.characterAvatar}
        />
        <div className={styles.notificationContent}>
          <p className={styles.title}>{data.title}</p>
          {data.ctaType === 'feed' && (
            <p
              className={styles.message}
              dangerouslySetInnerHTML={{ __html: data.message as string }}
            />
          )}
          {data.ctaType === 'postFailure' && (
            <div className={styles.retrySection}>
              {data.ctaType === 'postFailure' && (
                <Image
                  alt="error"
                  src={alertImg}
                  width={16}
                  height={16}
                  className={styles.errorIcon}
                />
              )}
              <Image
                alt="retry"
                src={retryImg}
                width={10}
                height={10}
              />
              <p className={styles.retry}>{t('retry')}</p>
            </div>
          )}
        </div>
        {!floating && (
          <div className={styles.rightSection}>
            {data.ctaType === 'feed' && (
              <>
                <Image
                  alt="open"
                  src={arrowImg}
                  width={16}
                  height={16}
                  className={styles.arrow}
                />
                <p className={styles.time}>{createdTime()}</p>
              </>
            )}
          </div>
        )}
      </div>
      {!floating && <div className={styles.divider} />}
      {floating && (
        <Image
          alt="close"
          src={closeImg}
          width={24}
          height={24}
          className={styles.close}
          onClick={markNotificationRead}
        />
      )}
    </div>
  );
};

export default NotificationRow;
