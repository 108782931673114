import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import { FormEvent, useEffect } from 'react';
import useApi from '@/hooks/useApi';
import { setError, setErrorToast } from '@/lib/features/deviceSlice';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { isDesktop } from '@/utils/device';
import { reportEvent } from '@/utils/api';
import { RootState } from '@/lib/store';

type IResponse = {
  status: string;
  body: Record<string, string> | string;
};

const TextBoxSection: React.FC<{
  title: string;
  required: boolean;
  feature: string;
  name: string;
  gender?: string;
  description?: string;
  background?: string;
  appearance?: string;
  characterGreeting?: string;
  value: string;
  placeholder: string;
  updateValue: (value: string) => void;
  className?: string;
  maxlength?: number;
  commonClass?: string;
  linkText?: string;
  action?: string;
}> = ({
  title,
  required,
  feature,
  name,
  gender = '',
  description = '',
  background = '',
  appearance = '',
  characterGreeting = '',
  updateValue,
  value,
  placeholder,
  className,
  maxlength,
  commonClass,
  linkText,
  action,
}) => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const {
    id,
    age,
    gender: userGender,
  } = useAppSelector((state: RootState) => state.user.userData);
  const { responseData, isLoading, error, fetchData } = useApi<IResponse>(
    'POST',
    `/ai/api/char/v1/${action ? action : 'aiGenerate'}`,
    action
      ? { input: value, feature: 'post_description' }
      : {
          name,
          feature,
          gender,
          description,
          background,
          appearance,
          characterGreeting,
          content: value,
        }
  );
  const handleInput = (e: FormEvent) => {
    const val = (e.target as HTMLInputElement).value;
    updateValue(val);
  };
  const generateResponse = () => {
    if (isLoading) {
      return;
    }
    if (action) {
      reportEvent({
        eventName: `feRephraseClicked`,
        userId: id as string,
        age,
        gender: userGender,
      });
    }
    fetchData();
  };
  useEffect(() => {
    if (responseData && responseData.status === 'Success') {
      const { body } = responseData;
      const content = action
        ? body || ''
        : (body as Record<string, string>)?.content || '';
      updateValue(content as string);
    } else if (error) {
      if (error && error.status === t('FailureUnauthorized')) {
        reportEvent({
          eventName: 'feErrorScreen',
          userId: id as string,
          age: age,
          gender: gender,
          extraData: {
            error: `${action ? action : 'aiGenerate'} API: ${error}`,
          },
        });
        dispatch(setError({ showErrorScreen: true }));
      } else {
        dispatch(setErrorToast({ showErrorToast: true }));
      }
    }
  }, [responseData, error]);
  return (
    <div
      className={`${styles.textBoxSection} ${
        isDesktop() ? styles.desktopTextSection : ''
      } ${isDesktop() && className ? styles[className] : ''}
      ${commonClass ? styles[commonClass] : ''}`}
    >
      <div className={styles.titleSection}>
        <p className={`${styles.title} ${required ? styles.required : ''}`}>
          {t(title)}
        </p>
        <p className={styles.linkTitle} onClick={generateResponse}>
          {isLoading
            ? t('generating')
            : linkText
            ? t(linkText)
            : t('generateWithAI')}
        </p>
      </div>
      <div className={styles.textareaContainer}>
        <textarea
          placeholder={placeholder}
          className={styles.valueInput}
          value={value}
          id={feature}
          maxLength={maxlength}
          onInput={(e) => handleInput(e)}
        />
        {maxlength && (
          <p className={styles.length}>{`${value.length} / ${maxlength}`}</p>
        )}
      </div>
    </div>
  );
};

export default TextBoxSection;
