import { reportEvent } from '@/utils/api';
import styles from './index.module.scss';
import notificationBellImg from '/public/static/assets/images/notificationBell.png';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { useEffect, useRef, useState } from 'react';
import { getFeDeviceDetails } from '@/utils/device';
import NotificationsConsole from './NotificationsConsole';
import useApi from '@/hooks/useApi';
import {
  setNotifData,
  setUnreadCount,
  updateNotifCursor,
} from '@/lib/features/notifSlice';
import { useSearchParams } from 'next/navigation';

export type NotificationData = {
  title: string;
  message: string;
  read: boolean; //initially set as false, when user clicks on it, It will become true
  imageUrl: string;
  ctaType: 'feed' | 'postFailure';
  ctaId: string;
  createdAt: { _seconds: number; _nanoseconds: number };
  updatedAt: { _seconds: number; _nanoseconds: number };
  id: string;
};

export type INotificationResponse = {
  status: string;
  body: {
    notificationData: {
      notifications: NotificationData[];
      pageInfo: {
        hasNextPage: boolean;
        cursor: string;
      };
    };
    unreadCount: number;
  };
};

const NotificationBell = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { id, age, gender, userVerified } = useAppSelector(
    (state: RootState) => state.user.userData
  );
  const [notificationsFetched, setNotificationsFetched] = useState<boolean>(false);
  const { charactersData } =
    useAppSelector((state: RootState) => state.charSlice);
  const [showNotificationConsole, setShowNotificationConsole] =
    useState<boolean>(false);
  const { unreadCount } = useAppSelector(
    (state: RootState) => state.notifSlice
  );
  const searchParams = useSearchParams();
  const activeCategory = searchParams.get('category');
  const selectedCategory = useRef(
    activeCategory
      ? String(activeCategory.toLowerCase()).charAt(0).toUpperCase() +
          String(activeCategory).slice(1)
      : 'Recommended'
  );
  const { responseData, fetchData, error } = useApi<INotificationResponse>(
    'GET',
    `/ai/api/char/v1/notifications?pageSize=15`
  );

  useEffect(() => {
    if (id && userVerified  && (charactersData[selectedCategory.current]?.data.length ||
      charactersData[selectedCategory.current]?.isDataFetched) && !notificationsFetched) {
      fetchData();
    }
  }, [id, userVerified, charactersData]);

  const viewNotificationsConsole = () => {
    reportEvent({
      eventName: 'feNotificationIconClicked',
      userId: id as string,
      age,
      gender,
      deviceType: getFeDeviceDetails().feDeviceType,
      extraData: {
        unreadNotificationCount: unreadCount,
      },
    });
    setShowNotificationConsole(true);
  };
  const closeNotificationsConsole = () => {
    setShowNotificationConsole(false);
  };

  useEffect(() => {
    if (responseData?.status === 'Success') {
      dispatch(setNotifData(responseData.body.notificationData.notifications));
      dispatch(setUnreadCount(responseData.body.unreadCount));
      const { hasNextPage, cursor } =
        responseData.body.notificationData.pageInfo;
      if (hasNextPage) {
        dispatch(
          updateNotifCursor({ isNextpageAvailable: true, pageCursor: cursor })
        );
      }
      setNotificationsFetched(true);
    }
  }, [responseData, error]);

  return (
    <div className={styles.notificationBell}>
      <Image
        alt="notifications"
        src={notificationBellImg}
        onClick={viewNotificationsConsole}
        className={styles.notificationBellImg}
        title={t('notifications')}
      />
      {unreadCount > 0 && (
        <div className={styles.notificationDot}>
          <p className={styles.notificationCount}>{unreadCount}</p>
        </div>
      )}
      {showNotificationConsole && (
        <NotificationsConsole onClose={closeNotificationsConsole} />
      )}
    </div>
  );
};
export default NotificationBell;
