import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import styles from './index.module.scss';
import { useTranslations } from 'next-intl';
import {
  Characters,
  setCharacters,
  setShowDeleteConfirmation,
} from '@/lib/features/userSlice';
import { isDesktop } from '@/utils/device';
import Button from '../Button';
import useApi from '@/hooks/useApi';
import { RootState } from '@/lib/store';
import { useEffect, useState } from 'react';
import { updateChatDataDisabled } from '@/lib/features/chatSlice';
import { setDeletedFeedId, setFeedData } from '@/lib/features/feedSlice';
import { CharFeedData } from '../Explore/types';
import { useParams, useRouter } from 'next/navigation';

const DeleteModal: React.FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { feedId } = useParams();
  const router = useRouter();
  const { showDeleteConfirmation, characters } = useAppSelector(
    (state: RootState) => state.user
  );
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [charDeleted, setCharDeleted] = useState(false);
  const { responseData, fetchData, error } = useApi<{ status: string }>(
    'POST',
    showDeleteConfirmation.indexOf('post_') !== -1
      ? `/ai/api/char/v1/deleteFeed`
      : `/ai/api/char/v1/deleteCharacter`,
    showDeleteConfirmation.indexOf('post_') !== -1
      ? { feedId: showDeleteConfirmation.replace('post_', '') }
      : { characterId: showDeleteConfirmation }
  );

  const handleCrossClick = () => {
    dispatch(setShowDeleteConfirmation(''));
  };

  const feeds =
    useAppSelector((state: RootState) => state.feedSlice.charFeedData) || [];
  const handleDeleteClick = () => {
    fetchData();
    setDeleteInProgress(true);
  };

  useEffect(() => {
    if (error || responseData?.status) {
      setDeleteInProgress(false);
      if (responseData?.status === 'Success') {
        setCharDeleted(true);
        if (showDeleteConfirmation.indexOf('post_') === -1) {
          const userCharacters = JSON.parse(JSON.stringify(characters));
          dispatch(
            setCharacters(
              userCharacters.filter(
                (char: Characters) =>
                  char.characterId !== showDeleteConfirmation
              )
            )
          );
          dispatch(updateChatDataDisabled({ charId: showDeleteConfirmation }));
        } else {
          const postId = showDeleteConfirmation.replace('post_', '');
          const userFeeds = JSON.parse(JSON.stringify(feeds));
          const updatedFeeds = userFeeds.filter(
            (post: CharFeedData) => post.id !== postId
          );
          dispatch(setFeedData(updatedFeeds));
          if (feedId === postId) {
            router.push('/dashboard/feeds');
          } else {
            dispatch(setDeletedFeedId(postId));
          }
        }
        setTimeout(() => {
          dispatch(setShowDeleteConfirmation(''));
        }, 2500);
      } else {
        dispatch(setShowDeleteConfirmation(''));
      }
    }
  }, [responseData, error]);

  return (
    <div
      className={`${isDesktop() ? styles.desktopCreatePost : ''} ${
        styles.createPost
      }`}
    >
      {deleteInProgress ? (
        <div className={styles.loading}>
          <p className={styles.loader} />
          <p>
            {showDeleteConfirmation.indexOf('post_') !== -1
              ? t('deletingPost')
              : t('deletingChar')}
          </p>
        </div>
      ) : charDeleted ? (
        <div className={styles.toast}>
          <p className={styles.successIcon} />
          <p>
            {showDeleteConfirmation.indexOf('post_') !== -1
              ? t('postSuccessfullyDeleted')
              : t('characterSuccessfullyDeleted')}
          </p>
        </div>
      ) : (
        <div className={styles.contentSection}>
          <div className={styles.titleHeader}>
            <p>
              {showDeleteConfirmation.indexOf('post_') !== -1
                ? t('confirmPostDeletion')
                : t('confirmCharacterDeletion')}
            </p>
            <p className={styles.crossIcon} onClick={handleCrossClick} />
          </div>
          <p className={styles.info}>
            {showDeleteConfirmation.indexOf('post_') !== -1 ? (
              <>
                <span>{t('confirmDeletePostInfo')}</span>
                <span>{t('Sure')}</span>
              </>
            ) : (
              t('confirmDeleteInfo')
            )}
          </p>
          <div className={styles.buttons}>
            <Button
              variant="outline"
              title="cancel"
              action={handleCrossClick}
            />
            <Button
              variant="darkBlue"
              title="delete"
              action={handleDeleteClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteModal;
