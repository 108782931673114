import styles from './index.module.scss';
import Image from 'next/image';
import feedImg from '/public/static/assets/images/feed.png';
import budsImg from '/public/static/assets/images/buds.png';
import chatsImg from '/public/static/assets/images/chat.png';
import createImg from '/public/static/assets/images/create.png';
import postImg from '/public/static/assets/images/post.png';
import cameraImg from '/public/static/assets/images/camera.svg';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { reportEvent } from '@/utils/api';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter } from 'next/navigation';
import {
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';
import { showCreatePost } from '@/lib/features/userSlice';

type IPayloadStats = {
  eventName: string;
  userId: string;
  chatSessionId?: string;
  deviceType?: string;
  personaId?: string;
  extraData?: Record<string, string | number | boolean>;
  source?: string;
  age?: string;
  gender?: string;
};

const Footer: React.FC = () => {
  const { id, age, gender, postInProgress, userCountryCode } = useAppSelector(
    (state: RootState) => state.user.userData
  );
  const footerTabs = userCountryCode === 'VN' ? ['feed', 'explore', 'chats'] : ['feed', 'explore', 'post', 'create', 'chats'];
  const { orientation } = useAppSelector(
    (state: RootState) => state.device.deviceData
  );
  const t = useTranslations();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const getImage = (footer: string) => {
    return footer === 'feed'
      ? feedImg
      : footer === 'explore'
      ? budsImg
      : footer === 'create'
      ? postImg
      : footer === 'camera'
      ? cameraImg
      : footer === 'post'
      ? createImg
      : chatsImg;
  };
  const sendStats = (footer: string, source?: string) => {
    const data: IPayloadStats = {
      eventName: `fe${footer}Click`,
      userId: id as string,
      age,
      gender,
    };
    if (source) {
      data.extraData = { source };
    }
    reportEvent(data);
  };
  const handleFooterClick = (footer: string) => {
    if (footer === 'create') {
      sendStats('CharCreate', 'footer');
      router.push('/dashboard/create');
    } else if (footer === 'post') {
      sendStats('CreatePost', 'footer');
      if (postInProgress) {
        dispatch(setErrorToastMessage(t('postCreationInProgress')));
        setTimeout(() => {
          dispatch(setErrorToast({ showErrorToast: true }));
        }, 100);
        return;
      }
      dispatch(showCreatePost(true));
    } else {
      sendStats(
        footer.replace(footer.charAt(0), footer.charAt(0).toUpperCase())
      );
      if (footer === 'feed') {
        router.push('/dashboard/feeds');
      } else if (footer === 'explore') {
        router.push('/dashboard/characters?category=recommended');
      } else if (footer === 'chats') {
        router.push('/dashboard/chats');
      }
    }
  };
  return (
    <ul
      id="footer"
      className={`${styles.footer} ${styles[orientation as string]}`}
    >
      {footerTabs.map((footer) => (
        <li
          key={footer}
          className={`${
            pathName.indexOf(footer) > -1 ||
            (footer === 'explore' && pathName.indexOf('characters') > -1)
              ? styles.active
              : ''
          }`}
          onClick={() => handleFooterClick(footer)}
        >
          <Image src={getImage(footer)} alt={footer} />
        </li>
      ))}
    </ul>
  );
};

export default Footer;
