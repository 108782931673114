import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import { useEffect } from 'react';
import {
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';

const ErrorToast: React.FC = () => {
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const errorToastMessage =
    useAppSelector(
      (state: RootState) => state.device.deviceData.errorToastMessage
    ) || t('unableToPerform');
  const errorToastPosition = useAppSelector((state: RootState) => state.device.deviceData.errorToastPosition) || 'bottom';
  useEffect(() => {
    setTimeout(() => {
      dispatch(setErrorToastMessage(t('unableToPerform')));
      dispatch(setErrorToast({ showErrorToast: false, errorToastPosition: 'bottom' }));
    }, 3000);
  }, []);
  return <div className={`${styles.errorToast} ${errorToastPosition === 'bottom' ? styles.bottom : styles.top}`}>{errorToastMessage}</div>;
};

export default ErrorToast;
