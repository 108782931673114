import Image from 'next/image';
import styles from './index.module.scss';
import headerLogo from '/public/static/assets/images/whiteHeaderLogo.png';
import mobileLogo from '/public/static/assets/images/mobileLogo.png';
import profileIcon from '/public/static/assets/images/profileIcon.png';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { setSearchValue, setShowLoginModal, showCreatePost } from '@/lib/features/userSlice';
import { RootState } from '@/lib/store';
import SearchBar from '../SearchBar';
import { FormEvent, useEffect, useState } from 'react';
import discord from '/public/static/assets/images/discord.png';
import searchIcon from '/public/static/assets/images/searchPurple.png';
import NSFWSwitch from '../NSFWSwitch';
import NotificationBell from '../Notification';
import { reportEvent } from '@/utils/api';
import { getFeDeviceDetails, isDesktop } from '@/utils/device';
import { useTranslations } from 'next-intl';
import DiscordPopup from '../DiscordPopup';
import Button from '../Button';
import {
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const router = useRouter();
  const { charId, feedId } = useParams();
  const [focus, setFocus] = useState(false);
  const [discordPopup, showDiscordPopup] = useState<boolean>(false);
  const t = useTranslations();
  const { searchValue, loginVisible } = useAppSelector(
    (state: RootState) => state.user
  );
  const [searchBar, showSearchBar] = useState(false);
  const { isSignedIn, avatar, id, age, gender, postInProgress, userCountryCode } =
    useAppSelector((state: RootState) => state.user.userData);
  const handleLogoClick = () => {
    router.push('/dashboard/characters?category=recommended');
  };
  const handleProfileClick = () => {
    reportEvent({
      eventName: 'feProfileClick',
      userId: id as string,
      age,
      gender,
    });
    if (isSignedIn)
      router.push('/dashboard/profile');
    else {
      dispatch(setShowLoginModal({
        showLoginModal: true,
        loginModalSource: 'profile',
      }));
    }
  };
  const handleSearchInput = (e: FormEvent) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    const trimmedValue = searchQuery.trim();
    if (!trimmedValue.length) {
      dispatch(setSearchValue(''));
    } else {
      dispatch(setSearchValue(searchQuery));
    }
  };
  const handleDiscordClick = () => {
    reportEvent({
      eventName: 'feDiscordIconClicked',
      userId: id as string,
      age,
      gender,
      deviceType: getFeDeviceDetails().feDeviceType,
    });
    showDiscordPopup(true);
  };
  const closeDiscordPopup = () => {
    showDiscordPopup(false);
  };
  const createPost = () => {
    reportEvent({
      eventName: `feCreatePostClick`,
      userId: id as string,
      age,
      gender,
      extraData: { source: 'header' },
    });
    if (postInProgress) {
      dispatch(setErrorToastMessage(t('postCreationInProgress')));
      setTimeout(() => {
        dispatch(
          setErrorToast({
            showErrorToast: true,
            errorToastPosition: isDesktop() ? 'top' : 'bottom',
          })
        );
      }, 100);
      return;
    }
    dispatch(showCreatePost(true));
  };
  useEffect(() => {
    const setSearchData = setTimeout(() => {
      if (searchValue.length > 2) {
        setFocus(false);
      }
    }, 400);
    return () => clearTimeout(setSearchData);
  }, [searchValue]);
  useEffect(() => {
    if (!isDesktop() && !pathName.includes('dashboard/characters')) {
      showSearchBar(false);
      dispatch(setSearchValue(''));
    }
  }, [charId]);
  return (
    <>
      <header
        className={`${isDesktop() ? styles.desktopHeader : ''} ${
          styles.header
        }`}
      >
        <Image
          src={isDesktop() ? headerLogo : mobileLogo}
          className={styles.headerLogo}
          alt="logo"
          onClick={handleLogoClick}
        />
        {isDesktop() && (
          <div className={styles.searchContainer}>
            {pathName.includes('dashboard/characters') &&
              !charId &&
              !feedId && (
                <SearchBar
                  handleSearchInput={handleSearchInput}
                  value={searchValue}
                  focus={focus}
                  className={'mr12'}
                />
              )}
            {pathName.includes('dashboard/characters') && userCountryCode !== 'VN' && (
              <Button
                variant={'createChar'}
                title="createPost"
                action={createPost}
              />
            )}
          </div>
        )}
        <div className={styles.headerIcons}>
          <div className={styles.iconsContainer}>
            {!loginVisible && (
              <>
                <NSFWSwitch />
                <span
                  className={`${styles.divider} ${
                    !isDesktop() &&
                    pathName.includes('dashboard/characters') &&
                    !charId &&
                    !feedId
                      ? styles.searchVisible
                      : ''
                  }`}
                />
              </>
            )}
            {!isDesktop() &&
              pathName.includes('dashboard/characters') &&
              !charId &&
              !feedId && (
                <Image
                  src={searchIcon}
                  className={styles.search}
                  alt="search"
                  onClick={() => showSearchBar(!searchBar)}
                />
              )}
            <NotificationBell />
            <Image
              alt="discord"
              src={discord}
              onClick={handleDiscordClick}
              className={styles.discord}
              title={t('joinDiscordServer')}
            />
          </div>
          <div
            className={`${styles.createIconContainer} ${
              pathName.includes('dashboard/create') ? styles.active : ''
            }`}
          >
            <Image
              src={isSignedIn && avatar ? avatar : profileIcon}
              alt="profile"
              width={24}
              height={24}
              className={styles.profileIcon}
              onClick={handleProfileClick}
            />
          </div>
        </div>
      </header>
      {searchBar && pathName.includes('/dashboard/characters') && (
        <div className={styles.searchBoxContainer}>
          <SearchBar
            handleSearchInput={handleSearchInput}
            value={searchValue}
            focus={focus}
            className={'margin12'}
          />
        </div>
      )}
      {discordPopup && <DiscordPopup closeDiscordPopup={closeDiscordPopup} />}
    </>
  );
};

export default Header;
