import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import styles from './index.module.scss';
import { RootState } from '@/lib/store';
import { useTranslations } from 'next-intl';
import { setSharePost } from '@/lib/features/userSlice';
import copyIcon from '/public/static/assets/images/copyIcon.png';
import copyHoverIcon from '/public/static/assets/images/copyIconHover.png';
import Image from 'next/image';
import { setErrorToast, setErrorToastMessage } from '@/lib/features/deviceSlice';
import { getFeDeviceDetails, isDesktop } from '@/utils/device';
import ShareOptions from '../ShareOptions';
import { reportEvent } from '@/utils/api';

const SharePost: React.FC = () => {
  const t = useTranslations();
  const {
    postTitle,
    postId,
    postAuthor
  } = useAppSelector((state: RootState) => state.user.sharePost);
  const { id, age, gender } = useAppSelector(
    (state: RootState) => state.user.userData
  );
  const dispatch = useAppDispatch();

  const handleCrossClick = () => {
    dispatch(setSharePost({ showShareModal: false, postTitle: '', postId: '', postAuthor: '' }));
  };
  const copyLink = () => {
    reportEvent({
      eventName: 'feCopyLinkClicked',
      userId: id as string,
      age,
      gender,
      deviceType: getFeDeviceDetails().feDeviceType,
      extraData: { source: 'sharePost' },
    });

    const el = document.createElement('textarea');
    el.value = `https://${window.location.hostname}/sup-ai/dashboard/${postId.startsWith('char', 0) ? 'characters' : 'feeds'}/${postId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    dispatch(setErrorToastMessage(t('copiedToClipboard')));
    setTimeout(() => {
      dispatch(setErrorToast({ showErrorToast: true }));
    }, 100);
  };

  return (
    <div className={`${isDesktop() ? styles.desktopCreatePost : ''} ${styles.createPost}`}>
      <div className={styles.contentSection}>
        <div className={styles.titleHeader}>
          <p>{t('shareWithFriends')}</p>
          <p className={styles.crossIcon} onClick={handleCrossClick} />
        </div>
        <div className={styles.postUploadedSection}>
          <div className={styles.postUploaded}>
            <p className={styles.postLink}>
              {`https://${window.location.hostname}/sup-ai/dashboard/${postId.startsWith('char', 0) ? 'characters' : 'feeds'}/${postId}`}
            </p>
            <Image src={copyIcon} alt="copy" onClick={copyLink}
              onMouseEnter={(e) => (e.currentTarget.src = copyHoverIcon.src)}
              onMouseLeave={(e) => (e.currentTarget.src = copyIcon.src)} />
          </div>
          <div className={styles.sharePostSection}>
            <ShareOptions
              postFeedId={postId}
              postTitle={postTitle}
              authorName={postAuthor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePost;
