import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import searchImage from '/public/static/assets/images/search.png';
import Image from 'next/image';
import { FormEvent, useEffect, useRef } from 'react';
import { isDesktop } from '@/utils/device';

const SearchBar: React.FC<{
  handleSearchInput: (e: FormEvent) => void;
  value: string;
  focus: boolean;
  className?: string;
}> = ({ handleSearchInput, value, focus, className }) => {
  const t = useTranslations();
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (focus) {
      inputRef?.current?.focus();
    }
  }, [focus]);
  return (
    <div
      className={`${isDesktop() ? styles.desktopSearch : ''} ${
        styles.searchBar
      } ${className ? styles[className] : ''}`}
    >
      <input
        type="search"
        ref={inputRef}
        value={value}
        className={styles.search}
        placeholder={t('search')}
        onInput={handleSearchInput}
      />
      <Image src={searchImage} alt="search" className={styles.searchImage} />
    </div>
  );
};

export default SearchBar;
