import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import Image from 'next/image';
import notificationBellImg from '/public/static/assets/images/notificationBell.png';
import closeImg from '/public/static/assets/images/closePlain.png';
import { useEffect, useRef } from 'react';
import { isDesktop } from '@/utils/device';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import useApi from '@/hooks/useApi';
import NotificationRow from '../NotificationRow';
import { setNotifData, updateNotifCursor } from '@/lib/features/notifSlice';
import { INotificationResponse } from '..';

const NotificationsConsole: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const notificationsConsoleRef = useRef<HTMLDivElement>(null);
  const fetchInProgress = useRef<boolean>(false);
  const nextpageAvailable = useRef<boolean>(false);
  const currCursor = useRef<string | null>(null);
  const { notificationData, isNextpageAvailable, pageCursor } = useAppSelector(
    (state: RootState) => state.notifSlice
  );

  const { responseData, fetchData, error } = useApi<INotificationResponse>(
    'GET',
    `/ai/api/char/v1/notifications?pageSize=15`
  );

  const overlayClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const trackScrolling = async () => {
    const scrollElm = notificationsConsoleRef.current;
    if (scrollElm) {
      const { scrollTop, clientHeight, scrollHeight } = scrollElm;
      if (
        scrollTop + clientHeight + 100 >= scrollHeight &&
        nextpageAvailable.current &&
        !fetchInProgress.current
      ) {
        fetchInProgress.current = true;
        await fetchData(
          `/ai/api/char/v1/notifications?pageSize=50&cursor=${pageCursor}`
        );
        setTimeout(() => {
          fetchInProgress.current = false;
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (responseData?.status === 'Success') {
      const { hasNextPage, cursor } =
        responseData.body.notificationData.pageInfo;

      dispatch(
        setNotifData([
          ...notificationData,
          ...responseData.body.notificationData.notifications,
        ])
      );
      if (hasNextPage) {
        nextpageAvailable.current = true;
        currCursor.current = cursor;
        dispatch(
          updateNotifCursor({ isNextpageAvailable: true, pageCursor: cursor })
        );
      } else {
        nextpageAvailable.current = false;
        currCursor.current = null;
        dispatch(
          updateNotifCursor({ isNextpageAvailable: false, pageCursor: null })
        );
        notificationsConsoleRef.current?.removeEventListener(
          'scroll',
          trackScrolling
        );
      }
    }
  }, [responseData, error]);

  useEffect(() => {
    nextpageAvailable.current = isNextpageAvailable;
    currCursor.current = pageCursor;
  }, []);

  return (
    <div className={styles.notificationOverlay} onClick={overlayClickHandler}>
      <div
        className={
          isDesktop()
            ? styles.notificationSection
            : styles.mobileNotificationSection
        }
      >
        <div className={styles.notificationHeader}>
          <Image
            alt="notifications"
            src={notificationBellImg}
            className={styles.notificationBell}
            title={t('notifications')}
          />
          <p>{t('notifications')}</p>
          <p className={styles.crossIcon} onClick={onClose} />
        </div>
        <div
          ref={notificationsConsoleRef}
          className={styles.notificationBody}
          onScroll={trackScrolling}
        >
          {notificationData.length > 0 ? (
            notificationData?.map((notification, index) => {
              return (
                <NotificationRow
                  data={notification}
                  className=""
                  key={index}
                  floating={false}
                  onClose={onClose}
                />
              );
            })
          ) : (
            <div className={styles.emptyNotificationBody}>
              <p>{t('noNotifications')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsConsole;
