import { useTranslations } from 'next-intl';
import styles from './index.module.scss';
import instaIcon from '/public/static/assets/images/instagram.png';
import fbIcon from '/public/static/assets/images/facebook.png';
import telegramIcon from '/public/static/assets/images/telegram.png';
import twitterIcon from '/public/static/assets/images/twitter.png';
import whatsappIcon from '/public/static/assets/images/whatsapp.png';
import Image from 'next/image';
import { getFeDeviceDetails, isDesktop } from '@/utils/device';
import { reportEvent } from '@/utils/api';
import { useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';

const ShareOptions: React.FC<{ postFeedId: string; postTitle: string; authorName: string }> = (
  { postFeedId, postTitle, authorName }
) => {
  const t = useTranslations();
  const { id } = useAppSelector(
    (state: RootState) => state.user.userData
  );

  const sendEvent = (platform: string) => {
    reportEvent({
      eventName: 'fePostShareSocialIconClicked',
      userId: id as string,
      deviceType: getFeDeviceDetails().feDeviceType,
      extraData: { feedId: postFeedId as string, platform },
    });
  };

  const shareText = () => {
    const shareData = {
      title: authorName,
      text: postTitle,
      url: `https://${window.location.hostname}/sup-ai/dashboard/${postFeedId.startsWith('char', 0) ? 'characters' : 'feeds'}/${postFeedId}`,
    }
    if (navigator.share) {
      navigator.share(shareData).catch(console.error)
    }
    sendEvent('instagram');
  };

  return (
    <div className={styles.sharePostSection}>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=https://${window.location.hostname}/sup-ai/dashboard/${postFeedId.startsWith('char', 0) ? 'characters' : 'feeds'}/${postFeedId}`}
        target="_blank">
        <Image className={styles.socialIcon} src={fbIcon} alt="FB" onClick={() => { sendEvent('facebook'); }} />
      </a>
      <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(postTitle || '')} \n\n https://${window.location.hostname}/sup-ai/dashboard/${postFeedId.startsWith('char', 0) ? 'characters' : 'feeds'}/${postFeedId}`}
        data-action="share/whatsapp/share" target="_blank">
        <Image className={styles.socialIcon} src={whatsappIcon} alt="Whatsapp" onClick={() => { sendEvent('whatsapp'); }} />
      </a>
      <a href={`https://telegram.me/share/url?url=${encodeURIComponent(postTitle || '')} \n\n https://${window.location.hostname}/sup-ai/dashboard/${postFeedId.startsWith('char', 0) ? 'characters' : 'feeds'}/${postFeedId}`}
        target="_blank">
        <Image className={styles.socialIcon} src={telegramIcon} alt="Telegram" onClick={() => { sendEvent('telegram'); }} />
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(postTitle || '')} \n\n https://${window.location.hostname}/sup-ai/dashboard/${postFeedId.startsWith('char', 0) ? 'characters' : 'feeds'}/${postFeedId}`}
        target="_blank">
        <Image className={styles.socialIcon} src={twitterIcon} alt="Twitter" onClick={() => { sendEvent('twitter'); }} />
      </a>
      {!isDesktop() && <Image className={styles.socialIcon} src={instaIcon} alt="Instagram" onClick={shareText} />}
    </div>
  );
};

export default ShareOptions;
